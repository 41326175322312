<template>
  <div>
    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Danh sách song ngành</b-card-title>
          </b-card-header>
          <b-card-body>
            <vue-good-table
                :columns="registerColumns"
                :rows="registerDiplomas"
                :pagination-options="paginationOptions"
            >
              <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                  slot="table-row"
                  slot-scope="props"
              >
                <span v-if="props.column.field === 'capacity'">
                  {{ props.row.currentQuantity }} / {{ props.row.maxQuantity }}
                </span>
                <span v-else-if="props.column.field === 'action'">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      :disabled="props.row.currentQuantity >= props.row.maxQuantity"
                      @click="onCreateStudentRegisterDiploma(props.row)"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title> Đã đăng ký</b-card-title>
          </b-card-header>
          <b-card-body>
            <vue-good-table
                :columns="registeredColumns"
                :rows="registeredDiploma"
                :pagination-options="paginationOptions"
            >
              <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                  slot="table-row"
                  slot-scope="props"
              >
                <span v-if="props.column.field === 'action'">
                  <b-button
                      v-if="props.row.status<1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="btn-icon"
                      size="sm"
                      @click="onDeleteStudentRegisterDiploma(props.row.id)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </span>
                <span v-else-if="props.column.field === 'status'">{{showStatusName(props.row.status)}}</span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardBody, BCardHeader, BCardTitle, BCol, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import { Status } from '@/const/flag'

export default {
  name: 'RegisterDiploma',
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      user: getUser(),
      registerColumns: [
        {
          label: '#',
          field: 'rowNum',
          width: '30px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: `Tên kế hoạch`,
          field: 'name',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Chương trình ĐT',
          field: 'programmeName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Khoa/Bộ môn',
          field: 'departmentName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ ĐK',
          field: 'semesterName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Khóa',
          field: 'courseName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Hệ ĐT',
          field: 'trainingSystemName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Số lượng ĐK',
          field: 'currentQty',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '80px',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      registeredColumns: [
        {
          label: '#',
          field: 'rowNum',
          width: '30px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: `Tên kế hoạch`,
          field: 'name',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Chương trình ĐT',
          field: 'programmeName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Khoa/Bộ môn',
          field: 'departmentName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ ĐK',
          field: 'semesterName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Khóa',
          field: 'courseName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Hệ ĐT',
          field: 'trainingSystemName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Số lượng ĐK',
          field: 'currentQty',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '80px',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      paginationOptions: {
        enabled: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      registerDiplomas: `diploma/dataLists`,
      totalRows: `diploma/totalRows`,
      registeredDiploma : 'diplomaStudent/dataLists',
      totalRowsRegistered: 'diplomaStudent/totalRows'
    }),
    studentClassId() {
      return this.user
          .programmes
          .find(element => element.id === this.user.portalProgrammeId)
          .studentClassId
    },
  },
  async created() {
    await this.getDataSourcesFromStore()
    await this.getDataSources({
      currentPage: 1,
      itemsPerPage: 100,
      studentId: this.user.studentId
    })
  },
  methods: {
    ...mapActions({
      getMainData: `diploma/getData`,
      getDataSources: 'diplomaStudent/getData',
      createStudentDiploma: 'diplomaStudent/createData',
      deleteStudentDiploma: 'diplomaStudent/deleteData'
    }),
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getMainData({
          currentPage: 1,
          itemsPerPage: 100
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

    async onCreateStudentRegisterDiploma(data) {
      this.isLoading = true
      try {
        const response = await this.createStudentDiploma({
          deleteMode: 'physical',
          studentId: this.user.studentId,
          diplomaId: data.id,
          note: '',
          status: 0
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getDataSources({
              currentPage: 1,
              itemsPerPage: 100,
              studentId: this.user.studentId
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onDeleteStudentRegisterDiploma(studentDiplomaId) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa dữ liệu?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="las la-check fs-3"></i> Đồng ý',
        cancelButtonText: '<i class="las la-times fs-3"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteStudentDiploma( studentDiplomaId)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getDataSources({
                    currentPage: 1,
                    itemsPerPage: 100,
                    studentId: this.user.studentId
                  })
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    showStatusName(id){
      return id === 1 ? 'Đã duyệt' : 'Đã gửi'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
